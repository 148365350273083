@media (min-width:1100px) {
    .header {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: space-around;
        padding-top: 2em;
        padding-bottom: 2em;
        background-color: #010101;
    }

    .headerElement {
        font-size: x-large;
        align-self: center;
    }

    .headerElementName {
        font-size: x-large;
        font-weight: bold;
        letter-spacing: 0.05em;
        align-self: center;
        color: white;
    }

    .headerElementName:hover {
        color: #aaa;
    }

    .headerSectionContainer {
        display: flex;
        justify-content: space-evenly;
    }

    .headerSection {
        padding-inline: 1em;
        display: flex;
        flex-direction: row;
        border-radius: 1em;
    }

    .headerSection:hover {
        background-color: #222;
    }

    .headerSection:active {
        background-color: #333;
    }

    .headerSectionName {
        font-size: large;
        color: white;
    }

    .headerSectionIcon {
        align-self: center;
        margin-right: 0.25em;
    }

    .headerLinkContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .sectionTitle {
        background-color: #160e61;
        color: #FFFFFF;
        font-size: xxx-large;
        text-align: center;
        padding: 0.25em;
    }

    .sectionTitleBlack {
        background-color: #010101;
        color: #FFFFFF;
        font-size: xxx-large;
        text-align: center;
        padding: 0.25em;
    }

    .iconLinkContainer {
        background-color: #1e108a;
        border-radius: 0.5em;
        color: white;
        padding-left: 1em;
        padding-right: 1em;
        display: flex;
        flex-direction: row;
        width: max-content;
        align-items: center;
        margin-top: 1em;
    }

    .iconLinkContainer:hover {
        background-color: #4337a5;
    }

    .iconLink {
        margin-left: 0.5em;
        color: white;
    }

    .iconLink:hover {
        color: #aaa;
    }

    .about {
        display: flex;
        background-color: black;
        color: white;
        align-items: center;
        flex-direction: column;
    }

    .aboutContainer {
        padding: 3em;
    }

    .aboutHeader {
        width: fit-content;
        font-size: 5em;
        font-weight: bold;
        margin-bottom: 0.25em;
        margin: 0 auto;
        /*border-right: .1em solid white;*/
        /*animation:
            blink 1s step-end infinite;*/
    }

    @keyframes blink {
        from, to { border-color: transparent }
        50% { border-color: white; }
    }

    .aboutBody {
        width: 50%;
        padding-bottom: 0.5em;
        text-align: center;
        font-size: xx-large;
        margin: auto;
        margin-top: 1em;
    }

    .projects {
        background-color: #2c237d;
        color: white;
        justify-content: center;
        display: flex;
    }

    .projectsList {
        list-style-type: none;
        width: 60%;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-top: 3em;
        margin-bottom: -2em;
    }

    .projectElement {
        padding-bottom: 1em;
        margin-bottom: 5em;
        border: 1px solid #a299f1;
        border-radius: 1em;
    }

    .projectTitle {
        font-size: x-large;
        text-align: center;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .projectBody {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }

    .projectImage {
        max-width: 100%;
        max-height: 100%;
    }

    .projectImageContainer {
        max-width: 50%;
        max-height: 100%;
    }

    .projectDescription {
        width: 40%;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .education {
        background-color: #2c237d;
        justify-content: center;
        display: flex;
    }

    .educationElement {
        margin-bottom: 3em;
        color: white;
        padding: 1em;
        border: 1px solid #a299f1;
        border-radius: 0.5em;
    }

    .educationList {
        margin-top: 3em;
        list-style-type: none;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 0;
    }

    .educationTitle {
        font-weight: bold;
        font-size: x-large;
        text-align: center;
        margin-bottom: 0.5em;
    }

    .educationBody {
        display: flex;
        justify-content: space-evenly;
    }

    .educationDetails {
        display: flex;
        flex-direction: column;
        font-size: large;
        text-align: center;
        justify-content: space-evenly;
        width: 60%;
    }

    .skills {
        /*background-color: #2c237d;*/
        background-color:black;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 3em;
        padding-bottom: 3em;
    }

    .skillElement {
        color: white;
        padding: 0.5em;
        width: 10%;
    }

    .skillElement:hover {
        background-color: #222;
        border-radius: 1em;
    }

    .skillsList {
        list-style-type: none;
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 0;
    }

    .skillTitle {
        font-size: large;
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
    }

    .skillImage {
        display: flex;
        justify-content: center;
        margin-top: 0.5em;
    }

    .contact {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: space-around;
        padding: 2em;
        background-color: #2c237d;
        color: white;
    }

    .footer {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: space-around;
        padding: 2em;
        
        background-color: #010101;
        color: white;
    }

    a:link { 
        text-decoration: none; 
        color: white;
    }
    a:visited { 
        text-decoration: none;
    }
    a:hover { 
        text-decoration: none;
    }
    a:active {
        text-decoration: none; 
    }

    html {
        scroll-behavior: smooth;
    }
}
@media (max-width:1099px) {
    .header {
        display: flex;
        flex-direction: column;
        padding-top: 2em;
        padding-bottom: 2em;
        background-color: #010101;
    }

    .headerElement {
        font-size: x-large;
    }

    .headerElementName {
        font-size: x-large;
        font-weight: bold;
        letter-spacing: 0.05em;
        align-self: center;
        color: white;
    }

    .headerElementName:hover {
        color: #aaa;
    }

    .headerSectionContainer {
        display: flex;
        justify-content: space-evenly;
    }

    .headerSection {
        display: flex;
        flex-direction: row;
        border-radius: 1em;
    }

    .headerSection:hover {
        background-color: #222;
    }

    .headerSection:active {
        background-color: #333;
    }

    .headerSectionName {
        font-size: large;
        color: white;
    }

    .headerSectionIcon {
        align-self: center;
        margin-right: 0.1em;
    }

    .headerLinkContainer {
        display: flex;
        justify-content: space-evenly;
    }

    .sectionTitle {
        background-color: #160e61;
        color: #FFFFFF;
        font-size: xx-large;
        text-align: center;
        padding: 0.25em;
    }

    .sectionTitleBlack {
        background-color: #010101;
        color: #FFFFFF;
        font-size: xxx-large;
        text-align: center;
        padding: 0.25em;
    }

    .iconLinkContainer {
        background-color: #1e108a;
        border-radius: 0.5em;
        color: white;
        padding-left: 1em;
        padding-right: 1em;
        display: flex;
        flex-direction: row;
        width: max-content;
        align-items: center;
        margin-top: 1em;
    }

    .iconLinkContainer:hover {
        background-color: #4337a5;
    }

    .iconLink {
        color: white;
    }

    .iconLink:hover {
        color: #aaa;
    }

    .about {
        display: flex;
        background-color: black;
        color: white;
        align-items: center;
        flex-direction: column;
    }

    .aboutContainer {
        padding: 1em;
    }

    .aboutHeader {
        text-align: center;
        font-size: 3em;
        font-weight: bold;
        margin-bottom: 0.25em;
    }

    .aboutBody {
        padding-bottom: 0.5em;
        text-align: center;
        font-size: x-large;
        margin: auto;
        margin-top: 1em;
    }

    .projects {
        background-color: #2c237d;
        color: white;
        justify-content: center;
        display: flex;
    }

    .projectsList {
        list-style-type: none;
        width: 99%;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-top: 3em;
        margin-bottom: -2em;
    }

    .projectElement {
        padding-bottom: 1em;
        margin-bottom: 3em;
        border: 1px solid #a299f1;
        border-radius: 1em;
    }

    .projectTitle {
        font-size: large;
        text-align: center;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .projectBody {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .projectImage {
        max-width: 100%;
        max-height: 100%;
    }

    .projectImageContainer {
        max-width: 80%;
        align-self: center;
        margin-bottom: 0.5em;
    }

    .projectDescription {
        width: 90%;
        align-self: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .education {
        background-color: #2c237d;
        justify-content: center;
        display: flex;
    }

    .educationElement {
        margin-bottom: 3em;
        color: white;
        padding: 1em;
        border: 1px solid #a299f1;
        border-radius: 0.5em;
    }

    .educationList {
        margin-top: 3em;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 0;
    }

    .educationTitle {
        font-weight: bold;
        font-size: large;
        text-align: center;
        margin-bottom: 0.5em;
    }

    .educationBody {
        display: flex;
        justify-content: space-evenly;
    }

    .educationDetails {
        display: flex;
        flex-direction: column;
        font-size: medium;
        text-align: center;
        justify-content: space-evenly;
        width: 60%
    }

    .skills {
        /*background-color: #2c237d;*/
        background-color:black;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .skillElement {
        color: white;
    }

    .skillElement:hover {
        background-color: #222;
        border-radius: 1em;
    }

    .skillsList {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }

    .skillTitle {
        font-size: large;
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
    }

    .skillImage {
        display: flex;
        justify-content: center;
        margin-top: 0.5em;
    }

    .contact {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: space-around;
        padding: 2em;
        background-color: #2c237d;
        color: white;
    }

    .footer {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: space-around;
        padding: 2em;
        background-color: #010101;
        color: white;
    }

    a:link { 
        text-decoration: none; 
        color: white;
    }
    a:visited { 
        text-decoration: none;
    }
    a:hover { 
        text-decoration: none;
    }
    a:active {
        text-decoration: none; 
    }

    html {
        scroll-behavior: smooth;
    }
}

.maintenanceHead {
    text-align: center;
    font-size: 10em;
}

.maintenanceBody {
    text-align: center;
    font-size: x-large;
}